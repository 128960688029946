<template>
	<div class="vehicleBlock" v-bind:class="{ expanded: (vehicle.saved === false), incompleteVehicleBlock: (vehicle.isComplete === false) }">
		<div class="unsavedVehicle" v-show="vehicle.saved !== true">
			<h2>Add your vehicle</h2>
			<div class="row formrow">
				
				<div class="car-info" v-bind:class="{ 'incompleteCar': (incompleteVehicle === true) }">
					<div class="car-info-inner">

						<div class="small-12 medium-6 columns end">
							<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['year']) != 'undefined') }">
								<label :for="'veh' + idx + 'year'">Year</label>
								<select tabindex="0"  :id="'veh' + idx + 'year'" v-model="vehicle.year" v-on:change="updatedYear()" v-show="incompleteVehicle !== true">
									<option value="">Select one...</option>
									<option v-for="year in years" :key="'vehicleyear-' + year" v-bind:value="year" v-text="year"></option>
								</select>
								<div class="prepop" v-show="incompleteVehicle === true">
									{{ vehicle.year }}
								</div>
								<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.year"></div>
							</div>
						</div>
						
						<div class="small-12 medium-6 columns end">
							<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['make']) != 'undefined') }">
								<label :for="'veh' + idx + 'make'">Make</label>
								<div v-show="incompleteVehicle !== true">
									<select tabindex="0" :id="'veh' + idx + 'make'" name="make" v-model="vehicle.make" v-show="fetchingMakes !== true" v-on:change="updatedMake()">
										<option value="">Select one...</option>
										<option v-for="(m, midx) in vehicle.allMakes" :key="'make-' + midx" v-bind:value="m" v-text="m"></option>
									</select>
									<div class="fetchProgress" v-show="fetchingMakes === true"><div class="lds-progress progblack"><div></div><div></div><div></div></div></div>
								</div>
								<div class="prepop" v-show="incompleteVehicle === true">
									{{ vehicle.make }}
								</div>
								<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.make"></div>
							</div>
						</div>
							
						<div class="small-12 medium-6 columns end">
							<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['model']) != 'undefined') }">
								<label :for="'veh' + idx + 'model'">Model</label>
								<div v-show="incompleteVehicle !== true">
									<select tabindex="0" :id="'veh' + idx + 'model'" name="model" v-model="vehicle.model" v-show="fetchingModels !== true" v-on:change="updatedModel()">
										<option value="">Select one...</option>
										<option v-for="(m, midx) in vehicle.allModels" :key="'model-' + midx" v-bind:value="m" v-text="m"></option>
									</select>
									<div class="fetchProgress" v-show="fetchingModels === true"><div class="lds-progress progblack"><div></div><div></div><div></div></div></div>
								</div>
								<div class="prepop" v-show="incompleteVehicle === true">
									{{ vehicle.model }}
								</div>
								<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.model"></div>
							</div>
						</div>
			
						<div class="small-12 medium-6 columns end">
							<div class="fieldwrap" v-show="incompleteVehicle !== true || displayed_submodel_shown === true" v-bind:class="{ validationErr: ((typeof validationMessages['body_style']) != 'undefined') }">
								<label :for="'veh' + idx + 'body_style'">Body style</label>
								<div v-show="incompleteVehicle !== true">
									<select tabindex="0" :id="'veh' + idx + 'body_style'" name="body_style" v-model="vehicle.body_style" v-show="fetchingStyles !== true">
										<option value="">Select one...</option>
										<option v-for="(s, sidx) in vehicle.allStyles" :key="'style-' + sidx" v-bind:value="s.vin + '%%' + s.submodel + '%%' + s.purchaseprice" v-text="s.submodel"></option>
									</select>
									<div class="fetchProgress" v-show="fetchingStyles === true"><div class="lds-progress progblack"><div></div><div></div><div></div></div></div>
								</div>
								<div class="prepop" v-show="incompleteVehicle === true" v-on:click="editCarFields()">
									{{ displayed_submodel }}
								</div>
								<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.body_style"></div>
							</div>
						</div>
						
					</div>
				</div>
	
				<div class="small-12 medium-6 columns end">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['use']) != 'undefined') }">
						<label :for="'veh' + idx + 'use'">How is this vehicle used?</label>
						<select tabindex="0" :id="'veh' + idx + 'use'" name="use" v-model="vehicle.use">
							<option value="">Select one...</option>
							<option value="Business">Business</option>
							<option value="Farming">Farming</option>
							<option value="Pleasure">Pleasure</option>
							<option value="To/From Work">To/From Work</option>
							<option value="To/From School">To/From School</option>
						</select>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.use"></div>
					</div>
				</div>
				
				<div class="small-12 medium-6 columns end" v-show="isCommute === true">
					<div class="fieldwrap"  v-bind:class="{ validationErr: ((typeof validationMessages['commute_miles']) != 'undefined') }">
						<label :for="'vehicle' + idx + '-commute_miles'">How many miles do you commute to work/school one way?</label>
						<input type="text" v-bind:id="'vehicle' + idx + '-commute_miles'" name="commute_miles" inputmode="numeric" v-model="vehicle.commute_miles" v-mask="{ regex: '\\d{1,3}', showMaskOnHover: false, showMaskOnFocus: false, placeholder: '' }" v-on:blur="populateAnnualMiles()">
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.commute_miles"></div>
					</div>
				</div>
					
				<div class="small-12 medium-6 columns end">
					<div class="fieldwrap"  v-bind:class="{ validationErr: ((typeof validationMessages['annual_miles']) != 'undefined') }">
						<label :for="'vehicle' + idx + '-annual_miles'">How many miles do you drive annually?</label>
						<input type="text" v-bind:id="'vehicle' + idx + '-annual_miles'" name="annual_miles" inputmode="numeric" v-model="annualMiles">
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.annual_miles"></div>
					</div>
				</div>
				
				<div class="small-12 medium-6 columns end">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['ownership']) != 'undefined') }">
						<label :for="'veh' + idx + 'ownership'">Is this vehicle owned or leased?</label>
						<select tabindex="0" :id="'veh' + idx + 'ownership'" name="ownership" v-model="vehicle.ownership">
							<option value="">Select one...</option>
							<option value="Owned">Owned (and do not make payments)</option>
							<option value="Lien">Owned (and do make payments)</option>
							<option value="Leased">Leased</option>
						</select>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.ownership"></div>
					</div>
				</div>

				<div class="small-12 medium-6 columns end">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['purchase_date']) != 'undefined') }">
						<label :for="'veh' + idx + 'purchase_date'">How long have you owned this vehicle?</label>
						<select :id="'veh' + idx + 'purchase_date'" name="purchase_date" v-model="vehicle.purchase_date">
							<option value="">Select one...</option>
							<option value="Less than 90 days">Less than 90 days</option>
							<option value="More than 90 days, less than a year">More than 90 days, less than a year</option>
							<option value="1 year to less than 2 years">1 year to less than 2 years</option>
							<option value="2 years to less than 3 years">2 years to less than 3 years</option>
							<option value="3 years to less than 4 years">3 years to less than 4 years</option>
							<option value="4 or more years">4 or more years</option>
						</select>
						<div class="validation" v-html="validationMessages.purchase_date"></div>
					</div>
				</div>
	
				<div class="small-12 medium-6 columns">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['abs']) != 'undefined') }">
						<label id="anti-lock-brakes">Does this vehicle have anti-lock brakes?</label>
						<div class="radioWrap" role="group" aria-labelledby="anti-lock-brakes">
							<div class="radioOption">
								<label v-bind:for="'vehicle' + idx + '-abs-Y'">
									<input tabindex="0" type="radio" v-model="vehicle.abs" v-bind:id="'vehicle' + idx + '-abs-Y'" value="Yes">
									<span class="selector">Yes</span>
								</label>
							</div>
							<div class="radioOption">
								<label v-bind:for="'vehicle' + idx + '-abs-N'">
									<input tabindex="0" type="radio" v-model="vehicle.abs" v-bind:id="'vehicle' + idx + '-abs-N'" value="No">
									<span class="selector">No</span>
								</label>
							</div>
						</div>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.abs"></div>
					</div>
				</div>
	
				<div class="small-12 medium-6 columns">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['alarm']) != 'undefined') }">
						<label id="anti-theft-device">Does this vehicle have an alarm or other anti-theft device?</label>
						<div class="radioWrap" role="group" aria-labelledby="anti-theft-device">
							<div class="radioOption">
								<label v-bind:for="'vehicle' + idx + '-alarm-Y'">
									<input tabindex="0" type="radio" v-model="vehicle.alarm" v-bind:id="'vehicle' + idx + '-alarm-Y'" value="Yes">
									<span class="selector">Yes</span>
								</label>
							</div>
							<div class="radioOption">
								<label v-bind:for="'vehicle' + idx + '-alarm-N'">
									<input tabindex="0" type="radio" v-model="vehicle.alarm" v-bind:id="'vehicle' + idx + '-alarm-N'" value="No">
									<span class="selector">No</span>
								</label>
							</div>
						</div>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.alarm"></div>
					</div>
				</div>

			</div>
			
			<div class="buttonwrap">
				<a class="noTrack deleteLink" href="#" title="Delete vehicle" v-on:click.prevent="deleteThis()">Delete</a>
				<input type="button" class="addElementButton" value="Save Vehicle" v-on:click.prevent="saveVehicle()">
			</div>
		</div>
		<div class="incompletePlusWrap tip" title="Add this vehicle" v-show="vehicle.saved === true && vehicle.isComplete === false && isAntique === false" v-on:click.prevent="unsaveThis()">
			<span class="plustext">add this vehicle</span> <icon-helper :glyph="'circle-plus'" :variant="'ui'" :classes="'fa-plus-circle'"></icon-helper>
		</div>
		<div class="unableToQuoteWrap" v-show="vehicle.saved === true && vehicle.isComplete === false && isAntique === true">
			<span class="plustext">unable to quote online</span>
		</div>
		<div class="savedVehicle" v-show="vehicle.saved === true">
			<div class="row">
				<div class="small-9 medium-10 columns successCol" v-show="vehicle.isComplete === true">
					<icon-helper :glyph="'circle-check'" :variant="'ui'" :classes="'fa-check-circle'"></icon-helper> {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
				</div>
				<div class="small-9 medium-10 columns successCol" v-show="vehicle.isComplete === false">
					{{ vehicleName }}
				</div>
				<div class="small-3 medium-2 columns gearCol" v-show="vehicle.isComplete === true">
					<icon-helper :glyph="'gear'" :variant="'ui'" :classes="'editControl'" v-on:click.native="unsaveThis()"></icon-helper>
					<icon-helper :glyph="'trash'" :variant="'ui'" :classes="'deleteControl'" v-on:click.native="deleteThis()"></icon-helper>
				</div>
			</div>
		</div>		
	</div>
</template>

<script>
export default {
	props: {
		vehicle: {
			type: Object, 
			required: true 
		}, 
		drivernames: {
			type: Array, 
			required: false
		}, 
		years: {
			type: Array, 
			required: false
		},
		idx: {
			type: Number, 
			required: true
		}
	}, 
	data: function() {
		return {
			assetRoot: 'cdn/', 
			fetchingMakes: false, 
			fetchingModels: false, 
			fetchingStyles: false, 
			validationMessages: {}, 
			editingCarFields: false, 
			detectedVehicleValues: {}
		}
	}, 
	computed: {
		isFirstVehicle: function() {
			return (this.idx == 0);
		}, 
		isCommute: function() {
			return (this.vehicle.use == 'To/From Work' || this.vehicle.use == 'To/From School');
		}, 
		isAntique: function() {
			return (window.isBlank(this.vehicle.year) === false && isNaN(this.vehicle.year) === false && parseInt(this.vehicle.year) < 1981);
		}, 
		validationFields: function() {
			return Object.keys(this.validationMessages);
		}, 
		hasValidationErrors: function() {
			return (this.validationFields.length > 0);
		}, 
		annualMiles: {
			get: function() {
				if (this.vehicle.annual_miles !== "") {
					return this.addThousandsSeparators(this.vehicle.annual_miles);
				}
				return '';
			}, 
			set: function(newValue) {
				const intv = newValue.replace(/\D/g,'');
				if (isNaN(intv)) { this.vehicle.annual_miles = ''; }
				if (this.vehicle.annual_miles == intv) { this.vehicle.annual_miles = ''; }
				this.vehicle.annual_miles = intv;
			}
		}, 
		vehicleName: function() {
			let name = '';
			let adequateName = false;
			if (window.isBlank(this.vehicle.year) === false && isNaN(this.vehicle.year) === false) {
				name += this.vehicle.year + ' ';
			}
			if (window.isBlank(this.vehicle.make) === false) {
				name += this.vehicle.make;
				adequateName = true;
			}
			if (window.isBlank(this.vehicle.model) === false) {
				name += ' ' + this.vehicle.model;
				adequateName = true;
			}
			if (adequateName === false) { 
				name += 'Vehicle';
			}
			return name;
		}, 
		incompleteVehicle: function() {
			return (this.vehicle.prepopulated === true && this.editingCarFields !== true);
		}, 
		displayed_submodel: function() {
			if (window.isBlank(this.vehicle.body_style)) { return ''; }
			var pcs = this.vehicle.body_style.split('%%');
			if (pcs.length > 1) { return pcs[1]; }
			return '';
		}, 
		displayed_submodel_shown: function() {
			return (this.displayed_submodel.length > 0);
		}
	}, 
	methods: {
		addThousandsSeparators: function(n) {
			const filtered_n = n.replace(/\D/g,''); // strip non-numeric characters
			let qty = filtered_n + "";
			return qty.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // add thousands separators
		}, 
		asset: function(str) {
			return this.assetRoot + str;
		}, 
		unsaveThis: function() {
			this.vehicle.saved = false;
		}, 
		deleteThis: function() {
			let self = this;
			window.vex.dialog.confirm({
				message: "Are you sure you want to delete this vehicle?", 
				callback: function(b) {
					if (b === true) {
						self.$emit('delete-vehicle');
					}
				}
			});
		}, 
		removeValidationError: function(fieldname) {
			if (typeof this.validationMessages[fieldname] != 'undefined') { 
				window.Vue.delete(this.validationMessages, fieldname);
				if (this.hasValidationErrors === false) {
					window.RateApp.showAlert(''); // Clear alert if there are no more errors.
				}
			}
		}, 
		updatedYear: async function() {
			this.vehicle.make = '';
			this.vehicle.model = '';
			this.vehicle.body_style = '';
			this.vehicle.allMakes = [];
			this.vehicle.allModels = [];
			this.vehicle.allStyles = [];
			if (window.isBlank(this.vehicle.year) === false && isNaN(this.vehicle.year) === false) {
				// They have selected a year. Fetch vehicle makes for this year.
				this.fetchingMakes = true;
				window.TrvSDK.getAutoVinMakes(this.vehicle.year, this.setVehicleMakes)
			}
		}, 
		setVehicleMakes: function(makes) {
			if (window.is.array(makes) === true && makes.length > 0) {
				this.$set(this.vehicle, 'allMakes', makes);
				this.fetchingMakes = false;
			}
		}, 
		updatedMake: async function() {
			this.vehicle.model = '';
			this.vehicle.body_style = '';
			this.vehicle.allModels = [];
			this.vehicle.allStyles = [];
			if (window.isBlank(this.vehicle.make) === false) {
				// They have selected a make. Fetch vehicle models.
				this.fetchingModels = true;
				window.TrvSDK.getAutoVinModels(this.vehicle.year, this.vehicle.make, this.setVehicleModels);
			}
		}, 
		setVehicleModels: function(models) {
			if (window.is.array(models) === true && models.length > 0) {
				this.$set(this.vehicle, 'allModels', models);
				this.fetchingModels = false;
			}
		}, 
		updatedModel: async function() {
			this.vehicle.body_style = '';
			this.vehicle.allStyles = [];
			if (window.isBlank(this.vehicle.model) === false) {
				// They have selected a model. Fetch body styles.
				this.fetchingStyles = true;
				window.TrvSDK.getAutoVinSubmodels(this.vehicle.year, this.vehicle.make, this.vehicle.model, this.setVehicleSubmodels);
			}
		}, 
		setVehicleSubmodels: function(submodels) {
			if (window.is.array(submodels) === true && submodels.length > 0) {
				this.$set(this.vehicle, 'allStyles', submodels);
				this.fetchingStyles = false;
			}
		}, 
		populateAnnualMiles: function() {
			if (isNaN(this.vehicle.commute_miles) === false) {
				var cm = parseInt(this.vehicle.commute_miles);
				if (cm > 0 && cm < 1000) {
					this.$set(this.vehicle, 'annual_miles', '' + (cm * 12 * 52));
				}
			}
		}, 
		setMakesAndContinue: function(makes) {
			let continueToModels = false;
			if (window.is.array(makes) === true && makes.length > 0) {
				this.$set(this.vehicle, 'allMakes', makes);
				if (window.is.inArray(this.detectedVehicleValues.make, makes)) {
					this.$set(this.vehicle, 'make', this.detectedVehicleValues.make);
					continueToModels = true;
				} else {
					// Clear out the values that aren't provided, or aren't usable, from the API.
					this.$set(this.vehicle, 'make', '');
					this.$set(this.vehicle, 'model', '');
					this.$set(this.vehicle, 'body_style', '');					
				}
				this.fetchingMakes = false;
				if (continueToModels === true) {
					this.fetchingModels = true;
					window.TrvSDK.getAutoVinModels(this.vehicle.year, this.vehicle.make, this.setModelsAndContinue);
				}
			}
		}, 
		setModelsAndContinue: function(models) {
			let continueToBody = false;
			if (window.is.array(models) === true && models.length > 0) {
				this.$set(this.vehicle, 'allModels', models);
				if (window.is.inArray(this.detectedVehicleValues.model, models)) {
					this.$set(this.vehicle, 'model', this.detectedVehicleValues.model);
					continueToBody = true;
				} else {
					// Clear out the values that aren't provided, or aren't usable, from the API.
					this.$set(this.vehicle, 'model', '');
					this.$set(this.vehicle, 'body_style', '');					
				}
				this.fetchingModels = false;
				if (continueToBody === true) {
					this.fetchingStyles = true;
					window.TrvSDK.getAutoVinSubmodels(this.vehicle.year, this.vehicle.make, this.vehicle.model, this.setSubmodelsAndContinue);
				}
			}
		}, 
		setSubmodelsAndContinue: function(submodels) {
			if (window.is.array(submodels) === true && submodels.length > 0) {
				this.$set(this.vehicle, 'allStyles', submodels);
				if (window.is.inArray(this.detectedVehicleValues.submodel, submodels)) {
					this.$set(this.vehicle, 'body_style', this.detectedVehicleValues.submodel)
				} else {
					// Clear out the values that aren't provided, or aren't usable, from the API.
					this.$set(this.vehicle, 'body_style', '');					
				}
				this.fetchingStyles = false;
			}
		}, 
		editCarFields: function() {
			this.detectedVehicleValues = {
				year: this.vehicle.year, 
				make: this.vehicle.make, 
				model: this.vehicle.model, 
				submodel: this.vehicle.body_style
			};
			if (window.isBlank(this.vehicle.year) === false && isNaN(this.vehicle.year) === false) {
				// See if we can populate the make with API-detected value.
				this.fetchingMakes = true;
				window.TrvSDK.getAutoVinMakes(this.vehicle.year, this.setMakesAndContinue);
			} else {
				// Clear out the values that aren't provided, or aren't usable, from the API.
				this.$set(this.vehicle, 'year', '');
				this.$set(this.vehicle, 'make', '');
				this.$set(this.vehicle, 'model', '');
				this.$set(this.vehicle, 'body_style', '');
			}
			this.editingCarFields = true;
		}, 
		isreq: function(fieldname) {
			// Check whether a field is required.
			return (window.is.inArray(fieldname, window.RateApp.requiredFields));
		}, 
		saveVehicle: function() {
			// Validate this vehicle. 
			if (this.isreq('vehicles.year') && (window.isBlank(this.vehicle.year) === true || isNaN(this.vehicle.year) === true)) { 
				this.$set(this.validationMessages, 'year', "Please select the year of this vehicle.");
			} else {
				this.removeValidationError('year');
			}
			if (this.isreq('vehicles.make') && window.isBlank(this.vehicle.make)) { 
				this.$set(this.validationMessages, 'make', "Please select the make of this vehicle.");
			} else {
				this.removeValidationError('make');
			}
			if (this.isreq('vehicles.model') && window.isBlank(this.vehicle.model)) { 
				this.$set(this.validationMessages, 'model', "Please select the model of this vehicle.");
			} else {
				this.removeValidationError('model');
			}
			if (this.isreq('vehicles.trim') && window.isBlank(this.vehicle.body_style)) { 
				if (this.incompleteVehicle === true) {
					this.$set(this.validationMessages, 'vin', "Could not auto-detect the body style of this vehicle. Please select it manuallly.");
				} else {
					this.$set(this.validationMessages, 'body_style', "Please select the body style of this vehicle.");
				}
			} else {
				this.removeValidationError('body_style');
				this.removeValidationError('vin');
			}
			if (this.isreq('vehicles.primary_use') && window.isBlank(this.vehicle.use)) { 
				this.$set(this.validationMessages, 'use', "Please select the primary use of this vehicle.");
			} else {
				this.removeValidationError('use');
			}
			if (this.isCommute === true && ((this.isreq('vehicles.mileage_commute') && (window.isBlank(this.vehicle.commute_miles) === true)) || (window.jQuery('#vehicle' + this.idx + '-commute_miles')[0].inputmask.isComplete() === false))) {
				this.$set(this.validationMessages, 'commute_miles', "Please indicate the length of your commute.");
			} else if (parseInt(this.vehicle.commute_miles) > 999) {
				this.$set(this.validationMessages, 'commute_miles', "The length of your commute may not be over 1,000 miles.");
			} else {
				this.removeValidationError('commute_miles');
			}
			if (this.isreq('vehicles.mileage_annual') && window.isBlank(this.vehicle.annual_miles)) {
				this.$set(this.validationMessages, 'annual_miles', 'Please enter your annual mileage.');
			} else if (window.isBlank(this.vehicle.annual_miles) === false && (parseInt(this.vehicle.annual_miles) < 1000 || parseInt(this.vehicle.annual_miles) > 100000)) { 
				this.$set(this.validationMessages, 'annual_miles', "The annual miles for this vehicle must be between 1,000 and 100,000.");
			} else {
				this.removeValidationError('annual_miles');
			}
			if (this.isreq('vehicles.antilock_discount') && ['Yes', 'No'].indexOf(this.vehicle.abs) == -1) {
				this.$set(this.validationMessages, 'abs', 'Please select Yes or No.');
			} else {
				this.removeValidationError('abs');
			}
			if (this.isreq('vehicles.antitheft_discount') && ['Yes', 'No'].indexOf(this.vehicle.alarm) == -1) {
				this.$set(this.validationMessages, 'alarm', 'Please select Yes or No.');
			} else {
				this.removeValidationError('alarm');
			}
			if (this.isreq('vehicles.ownership_status') && window.isBlank(this.vehicle.ownership)) {
				this.$set(this.validationMessages, 'ownership', "Please indicate whether you own this vehicle.");
			} else {
				this.removeValidationError('ownership');
			}
			if (this.isreq('vehicles.purchase_date') && window.isBlank(this.vehicle.purchase_date)) {
				this.$set(this.validationMessages, 'purchase_date', "Please indicate how long you have owned this vehicle.");
			} else {
				this.removeValidationError('purchase_date');
			}
			// Update the "saved" boolean for this vehicle.
			if (this.validationFields.length > 0) {
				window.RateApp.showDefaultAlert();
				return false;
			}
			this.$set(this.vehicle, 'isComplete', true);
			this.$set(this.vehicle, 'saved', true);
			window.Rates.scrollUp();
		}
	}
}
</script>